import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'reactstrap'
import styled from 'styled-components'

import HeroSection from '~components/layouts/marketing/HeroSection'
import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import scssVars from '~components/scss-variables'
import SEO from '~components/seo'

const ButtonWrapper = styled.div`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      width: 382px;
    }
  }
`

const SustainabilityPage = () => {
  return (
    <Layout>
      <SEO
        title="Sustainability"
        pathname="/sustainability"
        description="Our offerings allow us to restore leather goods that would otherwise be tossed and thrown away so that your beloved luxury goods can have a second life"
      />
      <Main>
        <HeroSection title="Sustainability"
          mobileImg={'https://leatherspa.imgix.net/IMG_7477.jpg?fit=crop&w=992&h=683'}
          desktopImg={'https://leatherspa.imgix.net/IMG_7477.jpg?fit=crop&w=1360&h=936'}>
          <h2>Sustainable Luxury</h2>
          <p>
            Our offerings allow us to restore leather goods that would otherwise be tossed and thrown away so that your beloved luxury goods can have a second life. To advance towards a circular economy, we strive to extend the longevity of your leather goods in an effort to reduce waste and create a more sustainable future for fashion and retail.
          </p>
          <p>
            We are also conscientious of excessive packaging and use materials that are recyclable to reduce our carbon footprint. Our leather-care products are developed in-house, which allows us to formulate ingredients without harsh chemicals and our machinery is held to the highest standards for energy efficiency and to reduce Greenhouse Gas (GHG) emissions.
          </p>
        </HeroSection>
        <Container>
          <ButtonWrapper className="my-5 my-lg-4">
            <Link to="/contact" role="button" className="btn btn-primary my-lg-5">Contact Us</Link>
          </ButtonWrapper>
        </Container>
      </Main>
    </Layout>
  )
}

export default SustainabilityPage
